body, html {
  font-family: 'Open Sans', sans-serif;
  width: 100%;
  height: 100%;
  overflow-y: hidden;
  overflow-x: hidden;
  margin: 0;
  padding: 0;
}

.content {
  height: 100%;
  width: 100%;
  display: flex;
  overflow-y: hidden;
  overflow-x: hidden;
}

.section {
  flex: 1 0 auto;
  height: 100%;
  width: 50%;
}

.hint {
  display: flex;
  font-family: 'Reenie Beanie', cursive;
  position: absolute;
  bottom: 10%;
  right: 5%;
  color: white;
  font-size: 30px;
  text-align: center;
  transform: rotate(-15deg);
}

.hint img {
  align-self: center;
  transform: translate(-10px, -5px) rotateX(180deg);
}

.section.info {
  position: relative;
  box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.55);
}

.visualizations-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(135deg, rgba(0, 0, 0, 0.05) 0%, rgba(75, 122, 102, 0.5) 100%);
  flex-direction: column;
}

.chart-padding-container {
  height: calc(50% - 125px);
  width: calc(100% - 250px);
  border: 1px rgba(75, 122, 102, 0.4) solid;
  background-color: rgba(255, 255, 255, 0.8);
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.20);
  border-radius: 2px;
  padding: 30px;
  margin: 20px;
}

.object {
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 100%;
  min-width: 100%;
}

.chart-padding-container:focus {
  outline: 2px rgb(255, 174, 0) solid;
  outline-offset: -1px;
}

.header {
  font-size: 40px;
  color: white;
  text-align: center;
  font-weight: 500;
}

.divider {
  width: 100%;
  height: 1px;
  min-height: 1px;
  margin: 5px 0 25px;
  background-color: rgb(255, 174, 0);
}

.description-text {
  font-size: 18px;
  margin-bottom: 20px;
  color: rgb(234, 236, 234);
  font-weight: 100;
  text-align: center;
  width: 100%;
}

.buttons-container {
  /* display: flex; */
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  height: 40px;
  display: block;
  margin-bottom: 20px;
}

button:disabled {
  opacity: 0.5;
  pointer-events: none;
}

.rounded-button {
  flex: 0 0 auto;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  text-transform: uppercase;
  font-weight: bold;
  padding: 10px 20px;
  border-radius: 30px;
  background-color: rgba(0, 0, 0, 0.2);
  margin-top: 10px;
  margin-right: 10px;
  border: 1px transparent solid;
  font-size: 12px;
  color: whitesmoke;
  transition: background-color 0.2s;
}

.rounded-button:hover {
  background-color: rgba(0, 0, 0, 0.35);
}

.rounded-button:focus {
  border: 1px rgb(255, 174, 0) solid;
  box-shadow: 0px 0px 20px -4px rgba(255, 174, 0);
}

.textWrapper {
  width: 80%;
  height: 50%;
  margin-top: 120px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.image {
  width: 100%;
  height: 100%;
  background-position: bottom;
  background-size: cover;
  background-image: url("ocean.jpg");
}

.overlay {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: linear-gradient(142deg, rgba(75, 122, 102, 1) 0%, rgba(0, 0, 0, 0) 100%);
}

.qv-st-data-cell-null-value {
  background-color: transparent;
}

.qv-st-data-cell:focus, .qv-st-header-cell:focus {
  outline: 2px rgb(255, 174, 0) solid;
}

.qv-st-data-cell, .qv-st-header-cell {
  overflow: visible;
}

.qv-selection-toolbar {
  background: linear-gradient(135deg, rgb(255, 255, 255) 0%, rgb(241, 241, 241) 100%);
}

.qvobjects {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
}

@media only screen and (max-width: 1200px) {
  body, html {
    height: 100%;
  }
  .content {
    flex-direction: column;
    overflow-y: auto;
  }
  .section {
    width: 100%;
    height: 1000px;
  }
  .section.info {
    height: 800px;
  }
}

@media only screen and (max-width: 650px), only screen and (max-height: 800px) and (min-width: 1200px) {
  .qvobject {
    height: calc(50% - 125px);
    margin: 15px;
  }
  .description-text {
    font-size: 15px;
  }
  .header {
    font-size: 35px;
  }
  .rounded-button {
    font-size: 10px;
  }
  .hint {
    bottom: calc(10% + 30px);
    font-size: 25px;
  }
}

.logged_in, .logged_out {
  opacity: 0;
}

code {
  font-weight: bold;
  font-size: 110%;
  color: #dbdbdb;
  text-decoration: underline;
}

#app_list {
  margin-top: 10px;
  list-style: none;
  font-size: 2vh;
  max-height: 300px;
  min-height: 300px;
  overflow: auto;
  color: #d2d2d2;
}

#app_list li {
  padding: 3px;
}